import React, { useEffect } from "react";

const RightAds = ({
  adClient = "ca-pub-2163002254965232",
  adSlot = "5865781524",
  adWidth = "100%",
  adHeight = "auto",
  adId = "ad-slot",
}) => {
  useEffect(() => {
    const loadAd = () => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error("Ad push error: ", e);
      }
    };

    const refreshAd = () => {
      const adSlotElement = document.getElementById(adId);
      if (adSlotElement) {
        adSlotElement.remove();
        const newAdSlot = document.createElement("ins");
        newAdSlot.className = "adsbygoogle";
        newAdSlot.id = adId;
        newAdSlot.style.display = "inline-block";
        newAdSlot.style.width = adWidth;
        newAdSlot.style.height = adHeight;
        newAdSlot.setAttribute("data-ad-client", adClient);
        newAdSlot.setAttribute("data-ad-slot", adSlot);
        newAdSlot.setAttribute("data-ad-format", "auto");
        newAdSlot.setAttribute("data-full-width-responsive", "true");
        document.querySelector(`.${adId}`).appendChild(newAdSlot);
        loadAd();
      }
    };

    const checkAndRefreshAd = () => {
      const adSlotElement = document.getElementById(adId);
      if (
        adSlotElement &&
        adSlotElement.getAttribute("data-ad-status") !== "filled"
      ) {
        refreshAd();
      }
    };

    loadAd(); // Initial ad load

    const interval = setInterval(checkAndRefreshAd, 5000); // Check and refresh every 5 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [adClient, adSlot, adWidth, adHeight, adId]);

  return (
    <div className={`${adId} text-center mx-auto my-4`}>
      <ins
        id={adId}
        className="adsbygoogle"
        style={{ display: "inline-block", width: adWidth, height: adHeight }}
        data-ad-client={adClient}
        data-ad-slot={adSlot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default RightAds;

