import React from 'react'
import AdCard01 from './Ads/AdCard01'
import AdCard02 from './Ads/AdCard02'
import AdCard03 from './Ads/AdCard03'
import AdUnitMobile from './Ads/AdUnitMobile'
import AdUnitPC from './Ads/AdUnitPC'

const GameContent = () => {
    return (
        <div className="md:px-10 min-h-screen px-5 py-6 bg-white md:m-10">
            <section>
                <h1 className="text-center text-2xl md:text-4xl font-bold text-gray-800 mb-8">
                    Free Online Games You Should Play Right Now | Including Subway Surfers & GTA5
                </h1>

                <p className="text-lg text-gray-700 mb-6">
                    The world of online gaming has grown significantly in recent years, providing gamers with a diverse range of genres to choose from, including high-powered action projects and relaxing puzzle games. The best part is that the majority of these games are free! If you like a fast-paced activity or something more calm, there is a game for everyone. Here, we'll look at some of the finest free online games accessible right now, including popular titles like Subway Surfers and GTA 5.
                </p>
                <AdCard01 />
                <div>
                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">A Runner Subway Surfers</h3>
                    <p className="text-lg text-gray-700 mb-6">
                        Subway Surfers is a popular free game online that provides a simple but engaging gameplay experience. You play as a character who sprints through the underworld, dodging trains, jumping over obstacles, and collecting cash. The game proves to be increasingly demanding, keeping players on their toes as the speed increases.
                    </p>
                    <h4 className="text-xl font-bold text-gray-800 mb-2">What reason would you want to play?</h4>
                    <ul className="list-disc pl-6 mb-6">
                        <li>Quick involvement that never becomes boring.</li>
                        <li>Colourful visuals and different surroundings.</li>
                        <li>Power-ups and upgrades increase the game's excitement.</li>
                    </ul>
                    <p>If you enjoy endless running, Subway Surfers should be on your list.</p>
                </div>

                <div>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4 mt-8">The Virtual World of Endless Possibility GTA 5 online</h2>
                    <p className="text-lg text-gray-700 mb-6">
                        The fact that GTA5 is known for being an exceptional game, its online component includes free events and goals that you may take part in without paying extra. GTA5 Online has a large open environment where you can explore, perform tasks, and team up with others for multiplayer disorder.
                    </p>
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">For what reason Would it be advisable for you to Play?</h3>
                    <ul className="list-disc pl-6 mb-6">
                        <li>Expansive open-world gameplay allows you to drive, fly, and even parachute.</li>
                        <li>There are various tasks available, including car racing and deadly shootouts.</li>
                        <li>Multiplayer mode allows you to play the game with others, which adds an extra element of social interaction.</li>
                    </ul>
                    <p>GTA5 Online is great for each and every person who loves playing free games with an open world and limitless choices.</p>
                </div>
                <AdCard02 />
                <div>
                    <h3 className="text-2xl font-semibold text-gray-800 mb-4 mt-8">Puzzle Games Perfect for a Relaxing</h3>
                    <p className="text-lg text-gray-700 mb-6">
                        Puzzle games are ideal for a loosening up challenge. Not every person is looking for thrilling action. Sometimes you need to play a game that both challenges and relaxes you. Free online puzzle games are great for those events, as they combine logic, strategy, and creativity.
                    </p>
                    <ul className="list-disc pl-6 mb-6">
                        <li><strong>Sudoku:</strong> A traditional number puzzle that could improve your thinking abilities.</li>
                        <li><strong>Candy Crush Saga:</strong> Match colorful candies to complete levels in this addictive game.</li>
                        <li><strong>2048:</strong> Merge numbers to track down the tricky 2048 tile. Simple but highly interesting.</li>
                    </ul>
                    <p>Puzzle games are great for people who value problem solving and wish to take part in something both mentally requesting and soothing.</p>
                </div>

                <div>
                    <h3 className="text-2xl font-semibold text-gray-800 mb-4 mt-8">Casual Games for Enjoying a Quick Break</h3>
                    <p className="text-lg text-gray-700 mb-6">
                        If you need something quick and simple, easygoing free online games are the best to go. These games are designed to be played in short spurts, making them perfect for when you simply have a few minutes to play.
                    </p>
                    <ul className="list-disc pl-6 mb-6">
                        <li><strong>Agar.io:</strong> In this simple but captivating game, you play as a microscopic cell that expands by swallowing smaller players.</li>
                        <li><strong>Slither.io:</strong> Control a snake-like monster, consume pellets, and make an effort not to crash with other players.</li>
                        <li><strong>Crossy Road:</strong> Guide your character across the road while keeping away from vehicles and obstacles.</li>
                    </ul>
                    <p>Casual games are perfect for gamers who need to relax and have some good times during brief breaks.</p>
                </div>

                <div>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4 mt-8">Why All Ages of Gamers Love Free Online Games?</h2>
                    <ul className="list-disc pl-6 mb-6">
                        <li>Simple accessibility - All you need is a device and an internet connection.</li>
                        <li>No financial commitment - You are able to play for free as much or as little as you like.</li>
                        <li>Diverse genres - From action-packed games to puzzle games, there's something to suit each player's preference.</li>
                    </ul>
                    <p>If you enjoy the steady challenge of puzzle games or the explosion of titles like Subway Surfers and GTA5 Online, the world of free online games has a lot to offer.</p>
                </div>
            </section>
            <AdCard03 />
            <section className="mt-12">
                <h1 className="text-center text-2xl md:text-4xl font-bold text-gray-800 mb-8">
                    Hidden Gems: Underrated Games You Need to Try
                </h1>
                <p className="text-lg text-gray-700 mb-6">
                    In the consistently growing gaming universe, a few titles, regardless of their splendor, frequently slip by everyone's notice. These unlikely treasures could need blockbuster marketing, however they shine with habit-forming ongoing gameplay, innovative mechanics, and the possibility to keep you hooked for quite a long time. Here we have hidden games that merit a spot on your must-try list:
                </p>
                <AdUnitMobile />
                {/* Burning Ball */}
                <div>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Burning Ball</h2>
                    <p className="text-lg text-gray-700 mb-6">
                        If you're a fan of high-speed challenges and extremely sharp reflexes, Burning Ball is your game. The premise is simple yet addictive: guide a fiery sphere through a labyrinth of impediments while keeping up with force and avoiding traps.
                    </p>
                    <p><strong>What makes it special:</strong> The core mechanic of keeping a ball alive by bouncing it off various obstacles is surprisingly engaging.</p>
                </div>

                {/* Candy Crush */}
                <div className='mt-4'>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Candy Crush</h2>
                    <p className="text-lg text-gray-700 mb-6">
                        While Candy Crush is an easily recognized name, it often gets neglected as a casual game. But underneath its sugary exterior lies a challenging and strategic puzzle game.
                    </p>
                    <p><strong>What Makes It Unique:</strong></p>
                    <ul className="list-disc pl-6 mb-6">
                        <li>Huge number of levels, ensuring you never run out of fun.</li>
                        <li>Weekly challenges and seasonal events to keep things fresh.</li>
                        <li>Relaxing visuals and satisfying audio effects that make each match enjoyable.</li>
                    </ul>
                </div>

                {/* Zombie Hunter */}
                <div>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Zombie Hunter</h2>
                    <p className="text-lg text-gray-700 mb-6">
                        Do you love a good zombie apocalypse? Zombie Hunter brings you into a world teeming with the undead, where survival relies on your wits and aim.
                    </p>
                    <p><strong>What Makes It Special:</strong></p>
                    <ul className="list-disc pl-6 mb-6">
                        <li>A wide range of weapons to customize your zombie-hunting arsenal.</li>
                        <li>Intense, heart-pounding gameplay.</li>
                        <li>Atmospheric visuals and sound effects that immerse you in a dystopian world.</li>
                    </ul>
                </div>
                <AdUnitPC />
                {/* Ball Bounce */}
                <div>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Ball Bounce</h2>
                    <p className="text-lg text-gray-700 mb-6">
                        Sometimes simplicity is key, and Ball Bounce excels at delivering a direct yet addictive gaming experience. Guide a bouncing ball through levels filled with obstacles and moving platforms.
                    </p>
                    <p><strong>What Makes It Special:</strong></p>
                    <ul className="list-disc pl-6 mb-6">
                        <li>Minimalistic yet charming graphics.</li>
                        <li>Progressively challenging levels that test your patience and skills.</li>
                        <li>Perfect for casual gaming during a relaxing break.</li>
                    </ul>
                </div>

                {/* Fighter Jet */}
                <div>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Fighter Jet</h2>
                    <p className="text-lg text-gray-700 mb-6">
                        For fans of action and aerial combat, Fighter Jet offers exciting dogfights and missions. Pilot powerful jets as you explore hostile skies and take down enemies.
                    </p>
                    <p><strong>What Makes It Special:</strong></p>
                    <ul className="list-disc pl-6 mb-6">
                        <li>Realistic flight dynamics and immersive gameplay.</li>
                        <li>Multiple jet options, each with unique abilities and upgrades.</li>
                        <li>Quick missions that keep your adrenaline pumping.</li>
                    </ul>
                </div>
            </section>
        </div>
    )
}

export default GameContent
